import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import ArticlesComponent from "../components/articles";
import "../assets/css/main.css";

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <div className="uk-section">
        <div className="uk-container uk-container-large">
          <ArticlesComponent articles={data.allStrapiArticle.edges} />
        </div>
      </div>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiArticle(sort: { fields: [created_at], order: DESC }) {
      edges {
        node {
          strapiId
          description
          slug
          title
          category {
            name
          }
          sub_category {
            name
          }
          products {
            name
            imgSrc
            affiLink
            isAmazonProduct
            recommended
          }
        }
      }
    }
  }
`;

export default IndexPage;
